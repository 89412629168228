import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";

import { format } from 'date-fns';

import adminLayout from "../../hoc/adminLayout";
import UserService from "../../services/user.service";

const ListSchedule = () => {

    /* ========================= User data start =============================== */
    const [userList, setuserList] = useState([]);
    const [searchstr, setsearchstr] = useState([]);

    const fetchData = () => {
        UserService.getAllUserData().then(
            (response) => {
                console.log(response);
                setuserList(response.data.user);
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                console.log(resMessage);
            }
        );
    }

    useEffect(() => {
        fetchData();
    }, []);

    /* ================================ Search User by Name ==============================*/

    const searchUserData = (e) => {
        var data = {
            "query": e.target.value
        };
        console.log(data);
        setsearchstr(e.target.value);
        UserService.searchUserData(data).then(
            (response) => {
                console.log(response);
                setuserList(response.data.user);
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                console.log(resMessage);
            }
        );
    };

    /* ========================= Delete schedule start ================================== */

    const deleteUser = (id, e) => {
        if (window.confirm('Are you sure you want to delete this User?')) {
            // Save it!
            UserService.deleteUser(id).then(
                (response) => {
                    console.log(response);
                    window.location.reload();
                },
                (error) => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    console.log(resMessage);
                }
            );
        } else {
            // Do nothing!
            console.log('Delete operation not occurred!');
        }
    };

    return (
        <>
            <div className="table-container" style={{ display: 'block' }}>
                <div className="row">
                    <div className="col">
                        <h5 className="pb-2 mb-2 mt-2">Users</h5>
                    </div>
                    <div className="col text-right">
                        <Link to={`/adduser/`} className="btn">Add User</Link>
                        <input type="text" name="searchstr" onChange={searchUserData} value={searchstr} />
                    </div>
                </div>
                <div className="d-flex text-muted">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Full Name</th>
                                <th>Email</th>
                                <th>Role</th>
                                <th>Created At</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>

                            {userList && userList.length > 0 && userList.map((userListObj, index) => (
                                <tr key={userListObj._id}>
                                    <td>{userListObj.fullname}</td>
                                    <td>{userListObj.email}</td>
                                    <td>{userListObj.role}</td>
                                    <td>{format(new Date(userListObj.createdAt), 'Pp')}</td>
                                    <td>
                                        <ul className="qr-action-area action-menu">
                                            <li>
                                                <Link to={`/edituser/${userListObj._id}`} className="dropdown-item"><i className="fa fa-pencil" aria-hidden="true"></i></Link>
                                            </li>
                                            <li>
                                                <Link className="dropdown-item text-danger" to="/users" onClick={e => deleteUser(userListObj._id, e)} title="Delete"><i className="fa fa-trash" aria-hidden="true"></i></Link>
                                            </li>
                                        </ul>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
};

export default adminLayout(ListSchedule);