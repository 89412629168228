import React, { useState, useEffect } from "react";

import { format } from 'date-fns';

import adminLayout from "../../hoc/adminLayout";
import UserService from "../../services/user.service";

const ListSchedule = () => {

    /* ========================= User data start =============================== */
    const [userList, setuserLogList] = useState([]);
    const [searchstr, setsearchstr] = useState([]);
    const [pageno, setpageNo] = useState(1);

    const fetchData = (pno, action = 'none') => {
        UserService.getAllUserLogData(pno, action).then(
            (response) => {
                // console.log(response);
                setuserLogList(response.data);
                setpageNo(response.nextpage);
            },
            (error) => {
                /*const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();*/

                // console.log(resMessage);
            }
        );
    }

    useEffect(() => {
        fetchData(1);
    }, []);

    /* ================================ Sort user by name ================================*/

    const sortUserData = (type, e) => {
        const data = {
            type: type
        };
        UserService.sortUserLogData(data).then(
            (response) => {
                //     console.log(response);
                setuserLogList(response.data.user);
            },
            (error) => {
                /*const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();*/

                // console.log(resMessage);
            }
        );
    };

    /* ================================ Search User by Name ==============================*/

    const searchUserLogData = (e) => {
        var data = {
            "query": e.target.value
        };
        // console.log(data);
        setsearchstr(e.target.value);
        UserService.searchUserLogData(data).then(
            (response) => {
                //     console.log(response);
                setuserLogList(response.data.user);
            },
            (error) => {
                /*const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();*/

                // console.log(resMessage);
            }
        );
    };

    /* ========================= Delete schedule start ================================== */
    return (
        <>
            <div className="table-container" style={{ display: 'block' }}>
                <div className="row">
                    <div className="col">
                        <h5 className="pb-2 mb-2 mt-2">Users Log</h5>
                    </div>
                    <div className="col text-right">
                        <input type="text" name="searchstr" onChange={searchUserLogData} value={searchstr} />
                    </div>
                </div>
                <div className="d-flex text-muted">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Full Name <button className="sort-btn" onClick={e => sortUserData('asc', e)}>Asc</button> <button className="sort-btn" onClick={e => sortUserData('desc', e)}>Desc</button> </th>
                                <th>Role</th>
                                <th>User Ip</th>
                                <th>User Agent</th>
                                <th>Event</th>
                                <th>Created At</th>
                            </tr>
                        </thead>
                        <tbody>

                            {userList && userList.length > 0 && userList.map((userListObj, index) => (
                                <tr key={userListObj._id}>
                                    <td>{userListObj.fullname}</td>
                                    <td>{userListObj.role}</td>
                                    <td>{userListObj.userip}</td>
                                    <td>{userListObj.useragent}</td>
                                    <td>{userListObj.event}</td>
                                    <td>{format(new Date(userListObj.createdAt), 'Pp')}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="showmore-section d-flex"><button className="btn" onClick={e => fetchData(pageno, 'prev')}>Previous Page</button> <button className="btn" onClick={e => fetchData(pageno, 'next')}>Next Page</button></div>
            </div>
        </>
    );
};

export default adminLayout(ListSchedule);