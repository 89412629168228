import React, { useState, useRef } from "react";

import Form from "react-validation/build/form";
import CheckButton from "react-validation/build/button";

import adminLayout from "../../hoc/adminLayout";
import UserService from "../../services/user.service";

const UploadForm = () => {
    const form = useRef();
    const checkBtn = useRef();

    const [table, setTableData] = useState([]); // Store files as an array
    const [error, setError] = useState(false);
    const [files, setFiles] = useState([]);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");
    const [messagetype, setMessageType] = useState("");

    const required = (value) => {
        if (!value) {
            return (
                <div className="invalid-feedback d-block">
                    This field is required!
                </div>
            );
        }
    };

    // Handle file selection
    const handleFileChange = (event) => {
        const selectedFiles = event.target.files;
        setFiles(Array.from(selectedFiles)); // Convert FileList to Array
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        setMessage("");
        setLoading(true);
        setMessageType("alert-danger");
        form.current.validateAll();

        // Prepare FormData to upload files
        const formData = new FormData();
        files.forEach((file) => {
            formData.append("uploadfile", file); // Append each file
        });

        if (checkBtn.current.context._errors.length === 0) {
            UserService.uploaddata(formData).then(
                (response) => {
                    setMessageType("alert-success");
                    setMessage(response.msg);
                    setLoading(false);                    
                    setFiles([]); // Clear selected files after successful upload
                    let failedRows = response.failedRows;
                    if(failedRows.length > 0){
                        setError(true);
                        setTableData(failedRows);
                    }
                    
                    
                },
                (error) => {
                    const resMessage = (error.response.data.error +' | '+ error.response.data.details).toString();
                    /*const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();*/

                    setLoading(false);
                    setMessage(resMessage);
                }
            );
        } else {
            setLoading(false);
        }
    };

    return (
        <>
            <div className="my-5 p-3 bg-body rounded shadow-sm">
                <section id="forms">
                    <article className="my-3" id="sizing">
                        <div className="upload-heading bd-heading sticky-xl-top align-self-start mt-4 mb-5 mt-xl-0 mb-xl-4">
                            <h3>Upload Product</h3>
                        </div>
                        <Form className="login-form" onSubmit={handleSubmit} ref={form}>
                            <div className="mb-3 col-12">
                                <label className="mb-3">Upload Excel File</label>
                                <input
                                    type="file"
                                    multiple
                                    className="form-control form-control-md"
                                    name="excelupload"
                                    onChange={handleFileChange}
                                    validations={[required]}
                                />
                            </div>
                            <div className="col-12">
                                <button className="btn btn-primary btn-md" disabled={loading}>
                                    {loading && (
                                        <span className="spinner-border spinner-border-sm"></span>
                                    )}
                                    <span>Upload Data</span>
                                </button>
                            </div>

                            {message && (
                                <div className="form-group mt-4">
                                    <div className={`alert ${messagetype}`} role="alert">
                                        {message}
                                    </div>
                                </div>
                            )}
                            <CheckButton style={{ display: "none" }} ref={checkBtn} />
                        </Form>
                        {table.length > 0 ? (<div className="upload-heading bd-heading sticky-xl-top align-self-start mt-4 mt-xl-0 mb-xl-4"><h5 style={{color: "red"}}>Error Lists</h5></div>):''}
                        {table.length > 0 ? (                        
                        <table border="1" style={{ width: "90%", margin: "0px auto", borderCollapse: "collapse" }}>
                            <thead>
                            <tr>
                                {/* Assuming the data objects have properties like "id", "name", "email" */}
                                <th>File</th>
                                <th>Row</th>
                                <th>Message</th>
                            </tr>
                            </thead>
                            <tbody>
                            {/* Map over the data to create a row for each item */}
                            {table.map((item, index) => (
                                <tr key={index+1}>
                                <td>{item.file}</td>
                                <td>{item.row}</td>
                                <td>{item.error}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                        ): ''}
                    </article>
                </section>
                
            </div>
        </>
    );
};

export default adminLayout(UploadForm);
