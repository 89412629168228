import React from "react";
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar'
import { Link, useNavigate } from 'react-router-dom';

import AuthService from "../services/auth.service";

const Sidebar = () => {

    const navigate = useNavigate();

    const signOutFunc = () => {
        AuthService.logout().then(
            (response) => {
                console.log(response);
                navigate("/");
                return false;
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                console.log(resMessage);
            }
        );
    };

    return <div className="border-end sidenav" id="sidebar-wrapper">
        <div className="sidebar-heading border-bottom ">
            <Link to="/dashboard">
                <img alt="EG Logo" style={{ width: 100 }} src={require('./../assets/images/white-logo.png')} />
            </Link>
        </div>
        <PerfectScrollbar className="sidebar-items">
            <ul className="list-unstyled ps-0">
                <li className="mb-1">
                    <Link tag="a" className="" to="/dashboard">
                        <i className="fa fa-dashboard"></i> Dashboard
                    </Link>
                </li>
                <li className="mb-1">
                    <Link tag="a" className="" to="/excelupload">
                        <i className="fa fa-upload"></i> Upload Excel
                    </Link>
                </li>
                <li className="mb-1">
                    <Link tag="a" className="" to="/categories">
                        <i className="fa fa-tag"></i> Category
                    </Link>
                </li>
                <li className="mb-1">
                    <Link tag="a" className="" to="/products">
                        <i className="fa fa-tag"></i> Products
                    </Link>
                </li>
                <li className="mb-1">
                    <Link tag="a" className="" to="/users">
                        <i className="fa fa-user"></i> Users
                    </Link>
                </li>
                <li className="mb-1">
                    <Link tag="a" className="" to="/userlog">
                        <i className="fa fa-user"></i> Users Log
                    </Link>
                </li>
                <li className="border-top my-3"></li>

            </ul>
        </PerfectScrollbar>
        <div className="dropdown fixed-bottom-dropdown">
            <a href="#" className="d-flex align-items-center text-decoration-none dropdown-toggle" id="dropdownUser2" data-bs-toggle="dropdown" aria-expanded="false">
                <img src="/admin-img.png" alt="" width="32" height="32" className="rounded-circle me-2" />
                <span>Admin</span>
            </a>
            <ul className="dropdown-menu text-small shadow" aria-labelledby="dropdownUser2">
                <li><Link className="dropdown-item" to="#" onClick={signOutFunc}><i className="fa fa-sign-out" aria-hidden="true"></i> Sign out</Link></li>
            </ul>
        </div>
    </div>
}

export default Sidebar;